import type {
  LocationAsRelativeRaw,
  RouteLocationNormalizedLoaded,
  RouteLocationRaw,
} from 'vue-router'

import { capitalize, omit } from 'lodash-es'
import type { BuildProviderType, DeployTrackingType } from '~/types/graphql'
import { ensureInt } from './utils'

export interface SearchContext {
  orgSlug: string
  environmentSlug?: string | string[]
  projectSlug?: string | string[]
  labelSlug?: string | string[]
  teamSlug?: string | string[]
  deploymentSlug?: string
}

export function getSearchRouteLinkContext(context: SearchContext, params: { name: string, value: string | string[] }[]): RouteLocationRaw {
  const queryParams = params.reduce((acc, param) => {
    return {
      ...acc,
      [param.name]: param.value,
    }
  }, {})

  return {
    name: 'search',
    params: {
      orgSlug: context.orgSlug,
    },
    query: {
      environment: context.environmentSlug,
      project: context.projectSlug,
      label: context.labelSlug,
      deployment: context.deploymentSlug,
      team: context.teamSlug,
      ...queryParams,
    },
  }
}

export function getTagSearchRouteLink(context: SearchContext, tag: string): RouteLocationRaw {
  return getSearchRouteLinkContext(context, [{ name: 'tag', value: tag }])
}

export function getSizeSearchRouteLink(context: SearchContext, size: string): RouteLocationRaw {
  return getSearchRouteLinkContext(context, [
    { name: 'size', value: capitalize(size) },
  ])
}

export function getAuthorSearchRouteLink(context: SearchContext, author: string): RouteLocationRaw {
  return getSearchRouteLinkContext(context, [{ name: 'author', value: author }])
}

export function getTeamSearchRouteLink(context: SearchContext, team: string): RouteLocationRaw {
  return getSearchRouteLinkContext(context, [{ name: 'team', value: team }])
}

export function getDateSearchRouteLink(context: SearchContext, endDate: string, days: number, health?: string[]): RouteLocationRaw {
  return getSearchRouteLinkContext(context, [
    { name: 'endDate', value: endDate },
    { name: 'days', value: days.toString() },
    { name: 'health', value: health || '' },
  ])
}

export function getTextSearchRouteLink(context: SearchContext, text: string | null): RouteLocationRaw {
  return getSearchRouteLinkContext(context, text ? [{ name: 'q', value: text }] : [])
}

export function getCommitSearchRouteLink(context: SearchContext, revision: string): RouteLocationRaw {
  return getSearchRouteLinkContext(context, [{ name: 'commit', value: revision }])
}

/*
 * Organization
 */
export function getOrganizationRouteLink(orgSlug: string): RouteLocationRaw {
  return { name: 'orgStatus', params: { orgSlug } }
}
export function getOrganizationBillingUrl(orgSlug: string): string {
  return `/organization/${orgSlug}/billing/`
}
export function getOrganizationChooseBillingUrl(orgSlug: string): string {
  return `/organization/${orgSlug}/choose_billing/`
}

export function getOrganizationMembersUrl(orgSlug: string): string {
  return `/organization/${orgSlug}/members/`
}

export function getRegenerateApiKeyUrl(orgSlug: string): string {
  return `/organization/${orgSlug}/regenerate_api_key/`
}

export function getManageOrganizationSamlUrl(orgSlug: string): string {
  return `/organization/${orgSlug}/saml/`
}

export function getOrganizationSettingsProjectsUrl(orgSlug: string): string {
  return `/organization/${orgSlug}/projects/`
}

export function getOrganizationSettingsLabelsUrl(orgSlug: string): string {
  return `/organization/${orgSlug}/labels/`
}

export function getOrganizationSettingsTeamsUrl(orgSlug: string): string {
  return `/organization/${orgSlug}/teams/`
}

export function getComparisonRouteLink(orgSlug: string): LocationAsRelativeRaw {
  return { name: 'comparison', params: { orgSlug } }
}

/*
 * Account
 */
export const getDeleteAccountUrl = (): string => '/account/delete_account/'
export function getManageNotificationsUrl(): string {
  return '/account/manage_notifications/'
}
export const getEmailsUnsubscribeUrl = (): string => '/account/unsubscribe/'
export const getMyIdentitiesUrl = (): string => '/account/my_identities/'
export const getLogoutUrl = (): string => '/account/logout/'

/*
 * Project
 */
export function getProjectURL(orgSlug: string, projectSlug: string): string {
  return `/${orgSlug}/${projectSlug}`
}

export function getProjectRouteLink(orgSlug: string, projectSlug: string): RouteLocationRaw {
  return {
    name: 'projectDashboard',
    params: { orgSlug, projectSlug },
  }
}

export function getEditProjectNotificationsURL(orgSlug: string, projectSlug: string): string {
  return `${getProjectURL(orgSlug, projectSlug)}/_slack_config/`
}

export function getAddErrorImpactSourceURL(orgSlug: string, projectSlug: string, provider: string): string {
  return `${getProjectURL(orgSlug, projectSlug)}/add_${provider}_error_impact_source/`
}

export function getAddBuildImpactSourceURL(orgSlug: string, projectSlug: string, provider: string): string {
  return `${getProjectURL(orgSlug, projectSlug)}/add_${provider}_build_impact_source`
}

export function getAddCustomImpactSourceURL(orgSlug: string, projectSlug: string): string {
  return `${getProjectURL(orgSlug, projectSlug)}/add_custom_metric_impact_source/`
}

export function getAddMetricImpactSourceURL(orgSlug: string, projectSlug: string, provider: string): string {
  return `${getProjectURL(orgSlug, projectSlug)}/add_${provider}_metric_impact_source`
}

export function getAddIncidentImpactSourceURL(orgSlug: string, projectSlug: string, provider: string): string {
  return `${getProjectURL(
    orgSlug,
    projectSlug,
  )}/add_${provider}_incident_impact_source`
}

export function getAddFFDeploymentRouteLink(orgSlug: string, projectSlug: string): RouteLocationRaw {
  return {
    name: 'addFeatureFlagDeployment',
    params: { orgSlug, projectSlug },
  }
}

export function getAddManualDeployRouteLink(orgSlug: string, projectSlug: string): RouteLocationRaw {
  return {
    name: 'addManualDeploy',
    params: { orgSlug, projectSlug },
  }
}

export function getProjectLockHistoryURL(orgSlug: string, projectSlug: string): string {
  return `${getProjectURL(orgSlug, projectSlug)}/lock_history`
}

export function getSetupWizardStep2URL(orgSlug: string, projectSlug: string): string {
  return `/${orgSlug}/setup/${projectSlug}/2_repo_configuration`
}

export function getProjectMetricsURL(orgSlug: string, projectSlug: string, tab = 'frequency'): string {
  return `${getProjectURL(orgSlug, projectSlug)}/metrics/${tab}`
}

export function getProjectMetricsRouteLink(orgSlug: string, projectSlug: string): RouteLocationRaw {
  return {
    name: 'projectMetrics',
    params: { orgSlug, projectSlug },
  }
}

export function getProjectWizardRepoConfigurationRouteLink(orgSlug: string, projectSlug: string, isCustomGit: boolean, deploymentSlug?: string): RouteLocationRaw {
  return {
    name: 'setupProjectWizardRepoConfiguration',
    params: { orgSlug, projectSlug, deploymentSlug },
    query: {
      provider: isCustomGit ? 'CUSTOM_GIT' : undefined,
    },
  }
}

export function getAddCodeDeploymentRepoConfigurationRouteLink(orgSlug: string, projectSlug: string, isCustomGit?: boolean, deploymentSlug?: string): RouteLocationRaw {
  return {
    name: 'addCodeDeploymentRepoConfiguration',
    params: { orgSlug, projectSlug, deploymentSlug },
    query: {
      provider: isCustomGit ? 'CUSTOM_GIT' : undefined,
    },
  }
}

export function getAddCodeDeploymentProviderRouteLink(orgSlug: string, projectSlug: string): RouteLocationRaw {
  return {
    name: 'addCodeDeploymentProviderSelection',
    params: { orgSlug, projectSlug },
  }
}

export function getEditCodeDeploymentDeployDetectionConfirmRouteLink(orgSlug: string, projectSlug: string, deploymentSlug: string, deployTrackingType: DeployTrackingType, buildProvider?: BuildProviderType): RouteLocationRaw {
  return {
    name: 'editCodeDeploymentDeployDetectionConfirm',
    params: { orgSlug, projectSlug, deploymentSlug },
    query: {
      deployTrackingType,
      buildProvider,
    },
  }
}

export function getProjectSettingsFeatureFlagsRouteLink(orgSlug: string, projectSlug: string): RouteLocationRaw {
  return {
    name: 'projectSettingsFeatureFlags',
    params: { orgSlug, projectSlug },
  }
}

/*
 * Deployment
 */
export function getDeploymentURL(orgSlug: string, deploymentSlug: string): string {
  return `/${orgSlug}/deployments/${deploymentSlug}`
}

export function getDeploymentRouteLink(orgSlug: string, deploymentSlug: string, query?: Record<string, string>): RouteLocationRaw {
  return {
    name: 'deployment',
    params: { orgSlug, deploymentSlug },
    query,
  }
}

export function getApprovalWizardRouteLink(orgSlug: string, deploymentSlug: string): RouteLocationRaw {
  return {
    name: 'approvalWizard',
    params: { orgSlug, deploymentSlug },
  }
}

export function getAddImpactSourceRouteLink(orgSlug: string, projectSlug: string): RouteLocationRaw {
  return {
    name: 'impactSourceForm',
    params: { orgSlug, projectSlug },
  }
}

export function getEditCodeDeploymentURL(orgSlug: string, projectSlug: string, deploymentSlug: string): string {
  return `/${orgSlug}/${projectSlug}/edit_code_deployment/${deploymentSlug}`
}

export function getEditFFDeploymentRouteLink(orgSlug: string, projectSlug: string, deploymentSlug: string): RouteLocationRaw {
  return {
    name: 'editFeatureFlagDeployment',
    params: { orgSlug, projectSlug, deploymentSlug },
  }
}

export function getDeleteDeploymentURL(orgSlug: string, deploymentSlug: string): string {
  return `${getDeploymentURL(orgSlug, deploymentSlug)}/_delete`
}

export function getDeploymentLockHistoryURL(orgSlug: string, projectSlug: string, deploymentSlug: string): string {
  return `${getProjectLockHistoryURL(
    orgSlug,
    projectSlug,
  )}?deployment_slug=${deploymentSlug}`
}

export function getDeployDetailsRouteLink({
  orgSlug,
  deploymentSlug,
  deploySlug,
  tab,
  projectSlug,
  environmentSlug,
}: {
  orgSlug: string
  deploymentSlug: string
  deploySlug: string
  tab: string
  projectSlug: string
  environmentSlug: string
}): RouteLocationRaw {
  return {
    name: tab || 'deploy',
    params: { orgSlug, deploymentSlug, deploySlug },
    state: { projectSlug },
    query: { env_slug: environmentSlug },
  }
}

export function getDeleteDeployURL(orgSlug: string, deploymentSlug: string, deploySlug: string): string {
  return `${getDeploymentURL(orgSlug, deploymentSlug)}/${deploySlug}/_delete/`
}

/*
 * Impact
 */
export function getImpactSourceDetailsRouteLink(orgSlug: string, projectSlug: string, impactSourceSlug: string): RouteLocationRaw {
  return {
    name: 'impactDetails',
    params: { orgSlug, projectSlug, impactSourceSlug },
  }
}

/*
 * Actions
 */
export function getActionsDocumentationURL(orgSlug: string): string {
  return `/organization/${orgSlug}/actions-documentation`
}

export function getPaginationParams(route: RouteLocationNormalizedLoaded, defaultParams: object) {
  const { first, last } = route.query
  if (first && last) {
    // eslint-disable-next-line no-alert
    alert(
      'Hmm, we seem to have tangled up your pagination. We need first or last but not both. Consider clearing all query params or navigating away and coming back.',
    )
    throw new Error(
      `Received both first and last when GraphQL will only accept one. First: ${first.toString()}. Last: ${last.toString()}`,
    )
  }
  if (first === undefined && last === undefined) {
    return defaultParams
  }
  else {
    return {
      ...omit(route.query, ['first', 'last']),
      first: ensureInt(first, undefined),
      last: ensureInt(last, undefined),
    }
  }
}
